import { createSlice } from '@reduxjs/toolkit'
import { setLoading } from './loader';
import { errorHandler } from '../shared/_helper/responseHelper';
import { service } from '../shared/_services/api_service';

const initialState = {
  jantaHistoryData: [],
  totalJantaHistory: 0,
  gameTracksData: [],
  isOpenModal:false,
}

export const jantaSlice = createSlice({
  name: "janta",
  initialState,
  reducers: {
    setJantaHistory(state, { payload }) {
      state.jantaHistoryData = payload.result
      state.totalJantaHistory = payload.total
      // if (payload.result.length > 0) {
      //   for (let index = 0; index < payload.result.length; index++) {
      //     const elementGameTracks = payload.result[index].gameTracks;

      //     elementGameTracks.forEach(element => {
      //       element.gamePlayType = payload.result[0]?.gamePlayType
      //       element.date = payload.result[0]?.date
      //       element.tableId = payload.result[0]?.tableId
      //       element.gameId = payload.result[0]?.gameId
      //       if (element.gCard.pure?.length > 0) {
      //         element.sowCard = element.gCard.pure.flat()
      //       } else if (element.gCard.impure?.length > 0) {
      //         element.sowCard = element.gCard.impure.flat()
      //       } else if (element.gCard.set?.length > 0) {
      //         element.sowCard = element.gCard.set.flat()
      //       } else if (element.gCard.dwd?.length > 0) {
      //         element.sowCard = element.gCard.dwd.flat()
      //       }
      //     });
      //   }
      //   state.gameTracksData = payload.result[0]?.gameTracks
      // } else {
      //   state.gameTracksData = []
      // }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setJantaHistory, isOpenModal, ModalToggle } = jantaSlice.actions;

export default jantaSlice.reducer;

/*LOGIN GET OTP*/
export function getJantaHistory(limit, offset, keyword, fromDate, toDate) {
  return async function getJantaHistoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getJantaHistory(limit, offset, keyword, fromDate, toDate).then(
        (response) => {
          dispatch(setJantaHistory(response.data))
          dispatch(setLoading(false))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}


