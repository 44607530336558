import React, { Fragment, useState, useContext } from "react";
import { Container, Form} from "reactstrap";
import { H5, P } from "../AbstractElements";

import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/logo.png"
import CustomizerContext from "../_helper/Customizer";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/authSlice";
// import { setLoading } from "../store/loader";

const Signin = () => {
  const dispatch = useDispatch();
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [logintype] = useState('SuperAdmin');
  const [submit, setSubmit] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  // const onValueChange = (event) => {
  //   setLogintype(event.target.value)
  // }
  const loginAuth = async (e) => {
    // e.preventDefault();
    // if (loginidValid() || passwordValid()) {
    //   setSubmit(true)
    //   return null
    // }
    setSubmit(false)
    dispatch(loginUser({ loginId: loginId, password: password, logintype: logintype, history, layoutURL }));
  };
  const loginidValid = () => {
    if (!loginId) {
      return "loginId is required";
    }
  }
  // const loginTypeValid = () => {
  //   if (!logintype) {
  //     return "Login Type is required";
  //   }
  // }
  const passwordValid = () => {
    if (!password) {
      return "password is required";
    } else if (password.length < 5) {
      return "password must be longer than or equal to 5 characters";
    }
  }
  // const navigater=(link)=>{
  //   if(link){
  //     dispatch(setLoading(true))
  //     history(link)
  //     setTimeout(() => {
  //       dispatch(setLoading(false))
  //     }, 800);
  //   }
  // }
  return (
    <Fragment>
      <Container fluid={true} className="login-page">
        <div className="container_login">
          <div className="screen">
            <div className="screen__content">
              <Form className="login">
                <div className="logo_container">
                  <div className="img_logo">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="text_login">
                  <H5 attrH5={{ className: "text-uppercase mb-0 opacity-75" }}>Sign In With Super Admin</H5>
                  <P attrPara={{ className: "opacity-75" }}>Enter your loginId & password to login</P>
                </div>
                <div className="login__field">
                  <i className="login__icon fa fa-user"></i>
                  <input type="text" className="login__input" placeholder="Login Id" onChange={(e) => setloginId(e.target.value)} value={loginId}/>
                  {submit && loginidValid() ? <span className='d-block font-danger'>{loginidValid()}</span> : ""}
                </div>
                <div className="login__field position-relative">
                  <i className="login__icon fa fa-lock"></i>
                  <input type={togglePassword ? "text" : "password"} className="login__input" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                    {/* <span className={togglePassword ? "" : "show"}></span> */}
                    {togglePassword?
                    <i className="login_eye fa fa-eye"></i>
                    :
                    <i className="login_eye fa fa-eye-slash"></i>
                  }
                  </div>
                  {submit && passwordValid() ? <span className='d-block font-danger'>{passwordValid()}</span> : ""}
                </div>
                <button className="login__submit" type="button" onClick={loginAuth}>
                  <span className="button__text">Log In Now</span>
                  <i className="button__icon fa fa-chevron-right"></i>
                </button>
              </Form>
              {/* <div className="social-login">
                <h3>log in via</h3>
                <div className="social-icons">
                  <a href="/agent_login" className="social-login__icon fa fa-instagram"></a>
                  <a href="/agent_login" className="social-login__icon fa fa-facebook"></a>
                  <a href="/agent_login" className="social-login__icon fa fa-twitter"></a>
                </div>
              </div> */}
            </div>
            <div className="screen__background">
              <span className="screen__background__shape screen__background__shape4"></span>
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </Container >
      <ToastContainer />
    </Fragment >
  );
};

export default Signin;
